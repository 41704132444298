<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1>Configuración</h1>
      </v-flex>
    </v-layout>

    <v-layout row wrap mt-2>
      <v-flex xs12 md5>
        <v-card class="ma-1" elevation="1">
          <v-card-title>
            Cuenta
          </v-card-title>

          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Usuario"
                  v-model="user.username"
                  filled
                  hide-details="auto"
                  prepend-icon="mdi-account"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Identificador"
                  v-model="user.userId"
                  filled
                  hide-details="auto"
                  prepend-icon="mdi-identifier"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Fcm token"
                  v-model="fcm_token"
                  filled
                  hide-details="auto"
                  prepend-icon="mdi-message"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>            
          </v-container>

          <v-card-actions class="text-center">
            
            <v-flex>
              <v-btn color="accent" @click="deleteNotifications">Eliminar mis notificaciones</v-btn>
            </v-flex>
            <!--<v-flex>
              <v-btn
                color="accent"
                @click="requestNotificationsPermission"
                v-show="!notificationsPermission"
                >Habilitar notificaciones</v-btn>
            </v-flex> -->           
          </v-card-actions>

          <v-card-text v-show="!notificationsPermission">
            <v-alert border="left" color="orange" type="error" class="mx-5">
              Las notificaciones están desactivadas.
            </v-alert>
          </v-card-text>

        </v-card>

        <v-card class="ma-1 mt-4" elevation="1" v-show="user.type == 'guest'">
          <v-card-title>
            Contraseña
          </v-card-title>

          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field                  
                  v-model="password"
                  filled
                  hide-details="auto"
                  :type="passwordType"
                  prepend-icon="mdi-key">
                  <v-icon slot="append" @click="seePass" title="Ver contraseña"> mdi-eye</v-icon>
                </v-text-field>
              </v-col>
            </v-row>                   
          </v-container>

          <v-card-actions class="text-center">            
            <v-flex>
              <v-btn color="accent" @click="changePassword">Cambiar contraseña</v-btn>
            </v-flex>      
          </v-card-actions>

        </v-card>

      </v-flex>

      <v-flex xs12 md7>
        <v-card class="ma-1" elevation="1">
          <v-card-title>
            Canales de suscripción
          </v-card-title>

          <v-container>
            <v-progress-linear
              v-show="loading"
              :indeterminate="loading"
              color="primary"
              class="mt-1">
            </v-progress-linear>
            
            <v-row class="ma-1">
              <v-col
                v-for="item in channelsVM.general"
                :key="item.id"
                style="padding-top:2px;padding-bottom:2px; min-width:200px">
                <v-switch
                  :label="item.channelName"
                  color="primary"
                  v-model="selectedChannels"
                  :value="item.id"
                  hide-details
                  @change="change"
                  :disabled="item.disabled"></v-switch>
              </v-col>
            </v-row>

            <v-expansion-panels class="mt-5" v-model="activedPanels" multiple>
              <v-expansion-panel v-for="group in channelsVM.grouped" :key="group.name">
                <v-expansion-panel-header>{{group.name}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="ma-1">
                  <v-col
                    v-for="item in group.channels"
                    :key="item.id"
                    style="padding-top:2px;padding-bottom:2px; min-width:200px">
                    <v-switch 
                      :label="item.description? item.description : item.channelName"
                      color="primary"
                      v-model="selectedChannels"
                      :value="item.id"
                      hide-details
                      @change="change"
                      :disabled="item.disabled"></v-switch>
                  </v-col>
                </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>                      
            </v-expansion-panels>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <vue-confirm-dialog></vue-confirm-dialog>
    <CustomDialog></CustomDialog>
  </v-container>
</template>
<script>

import Vue from "vue";
import VueConfirmDialog from "vue-confirm-dialog";
import CustomDialog from "../components/CustomDialog.vue";

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

export default {
  components: {CustomDialog},
  name: "Configuration",
  data: () => ({
    selectedChannels: [],
    oldChannels: [],
    fcm_token: null,
    notificationsPermission: false,
    channelsVM: { general: [], grouped: [], expaded:[]},
    activedPanels: [],
    password: '',
    passwordType: "password"
  }),
  mounted() {
    var mode = Vue.$cookies.get("mode");

    if (mode != null) {
      this.tableMode = mode === "true";
    }


  },
  created() {
    
    this.$store.dispatch("fetchGroups");
    this.$store.dispatch("fetchUser").then(()=>{

    let _noGroupsInfo = this.$store.getters.getNoGroupsInfo;

    if (!_noGroupsInfo && (!this.user.groups || ( this.user.groups && this.user.groups.length == 0)))
    {
        this.$store.commit("setNoGroupsInfo", true);
        this.$store.commit("showCustomDialog", {
            title: "Activación de permisos",
            body:
              "Alta registrada correctamente. Por favor, para activarte los permisos a los canales de notificación, envía un mail a mobilitylabs@emtmadrid.es",
          });
    }

      this.$store.dispatch("fetchChannels").then(() => {
        this.$store.dispatch("fetchUserChannels").then(res => {
          
          // obtención de los canales seleccionados por el usuario
          for (let i = 0; i < this.channels.length; i++) {
            for (let j = 0; j < res.length; j++) {
              if (res[j].toLowerCase() == this.channels[i].channelName.toLowerCase()) {
                this.selectedChannels.push(this.channels[i].id);
              }
            }
          }

          this.oldChannels = this.selectedChannels;     

          let vChannelsEnabled = [];
          
          if (this.user.groups){
            this.user.groups.forEach(ug => {          
              let _g = this.groups.filter(g => g._id == ug);
              if (_g && _g.length > 0)
                vChannelsEnabled = vChannelsEnabled.concat(_g[0].channels);
            });
          }
          
          // obtención del objeto de vista para pintar los grupos de paneles con sus canales
          for (let i = 0; i < this.channels.length; i++) {  
            var c = this.channels[i];  
            
            c.disabled = vChannelsEnabled.indexOf(c.channelName) == -1;

            if (c.group == null) this.channelsVM.general.push(c);
            else {
              var group = this.channelsVM.grouped.filter(g => g.name == c.group);
              if (group.length > 0) //Si el grupo ya existe
                group[0].channels.push(c);
              else {
                //añado nuevo panel con el canal
                let g = { name: c.group, channels: new Array(c)}
                this.channelsVM.grouped.push(g);
                group.push(g)              
              }            

              //copruebo si está expadido
              if (this.oldChannels.findIndex(s => s == c.id) != -1)
              {
                this.activedPanels.push(this.channelsVM.grouped.indexOf(group[0]));      
              }            
            }
          }
        
        });

      });

    });
    

    //visibilidad de boton de pedir permiso
    this.notificationsPermission = Notification.permission == "granted";

    this.$messaging
      .getToken({
        vapidKey:
          "BPovmIh1otNekHN7B2aKaePCQSTdpffdy--CVeNdNz2S392GVuUed12mA1s-YIocLjcFT14YCFh4KyJvuxrWXuo",
      })
      .then(response => {
        this.$store.dispatch("registerDevice", response);
        this.fcm_token = response;
      });
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    user() {
      return this.$store.getters.getUser;
    },
    groups() {
      return this.$store.getters.getGroups;
    },
    channels() {
      return this.$store.getters.getChannels;
    },
  },
  methods: {
    requestNotificationsPermission() {      
      Notification.requestPermission().then(function(permission) { console.log('permiss', permission)});
    },
    groupChannels(name){
      return this.$store.getters.getChannels.filter(c => c.group == name.group);
    },
    testNotification() {
      this.$store.dispatch("testNotification");
    },
    seePass() {
      this.passwordType = (this.passwordType == "text") ? this.passwordType = "password" : this.passwordType = "text";      
    },
    deleteNotifications() {
      this.$store.dispatch("deleteOwnNotifications");
      this.$store.commit("showBanner", {
        bannerMessage: "Se han borrado tus notificaciones.",
        bannerType: "success",
      });
    },
    change(values) {
      let dif = values.filter(x => this.oldChannels.indexOf(x) === -1);
      if (dif.length > 0) {
        this.$store.dispatch("subscribeChannel", dif[0]);
      }

      dif = this.oldChannels.filter(x => values.indexOf(x) === -1);
      if (dif.length > 0) {
        this.$store.dispatch("unsubscribeChannel", dif[0]);
      }

      this.oldChannels = values;
    },
    changePassword() {
      if (!this.password || this.password.trim() == '')
      {
        this.$store.commit("showBanner", {
          bannerMessage: "Debe especificar la nueva contraseña.",
          bannerType: "error",
        });
        return;
      }

      let msg =  "Vas a cambiar tu contraseña ¿Deseas continuar?"
      this.$confirm({
        title: "Cambiar contraseña",
        message: msg,
        button: {
          yes: "Sí",
          no: "No",
        },
        callback: confirm => {
          if (confirm) {
            this.$store
              .dispatch("changePassword", {password: this.password})
              .then(() => {
                this.$store.commit("showBanner", {
                  bannerMessage: "Contraseña cambiada correctamente.",
                  bannerType: "success",
                });
              });

            this.password = '';             
          }
        },
      });

    },

  },
};
</script>

<style scoped>
.v-card__title {
  padding-bottom: -4px !important;
  margin-bottom: -30px !important;
}
</style>
